import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FontAwesome from './FontAwesome'

FontAwesome()

const Header = ({ logo, color, menu, lien }) => (
  <HeaderWrapper as="header">
    <LogoWrapper>
      <Link to="/">
        <img src={logo} alt="Piano Lancelot" />
      </Link>
    </LogoWrapper>
    <NavWrapper>
      <ul style={{ color: color }}>
        <li>
          <Link to={lien} style={{ color: color }}>
            {menu}
          </Link>
        </li>
        <li>
          <a
            style={{ color: color }}
            href="https://www.facebook.com/Piano-Lancelot-Cours-de-piano-%C3%A0-domicile-101463175109579/"
          >
            <FontAwesomeIcon icon={['fab', 'facebook']} />
          </a>
        </li>
        <li>
          <a
            style={{ color: color }}
            href="https://www.instagram.com/piano_lancelot/"
          >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </a>
        </li>
      </ul>
    </NavWrapper>
  </HeaderWrapper>
)

const HeaderWrapper = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  position: relative;
  z-index: 30000;
  display: flex;
`

const LogoWrapper = styled.div`
  height: 100px;
  width: 50%;

  img {
    width: 12rem;
    margin: 0.5rem 2rem;
  }
  @media screen and (max-width: 700px) {
    margin: 1rem 0;
  }
`

const NavWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
  font-family: 'Josefin Sans', sans-serif;
  width: 50%;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  ul li {
    list-style: none;
    text-align: center;
  }
  ul li a {
    display: block;
    text-decoration: none;
    padding: 2rem 0.5rem;
  }

  ul li a:hover {
    color: #38b6ff !important;
    transition: 0.6s ease-in-out;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
