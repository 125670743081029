import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

export default function FontAwesome() {
  library.add(
    fab,
    faTwitterSquare,
    faFacebook,
    faInstagram,
  )
}
