import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FontAwesome from './FontAwesome'
import { Link } from 'gatsby'

FontAwesome()

const Footer = () => {
  return (
    <WrapperFooter as="footer">
      <div className="wrapperFooterCli">
        <div>
          <h2>A propos</h2>
          <ul>
            <li>
              <Link to="EveilMusical">Eveil Musical</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2>Information</h2>
          <ul>
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
            <li>
              <Link to="/Mentions">Mentions légales</Link>
            </li>
          </ul>
          <h4>Partenaires</h4>
          <ul>
            <li>
              <a href="http://www.choreartevents.fr">Choréart events</a>
            </li>
            <li>
              <a href="http://www.mvdev.fr">Developpement web MVDev</a>
            </li>
          </ul>
        </div>
        <div>
          <h2>Nous suivre</h2>
          <ul className="icon">
            <li>
              {' '}
              <a href="https://www.facebook.com/https://www.facebook.com/Piano-Lancelot-Cours-de-piano-%C3%A0-domicile-101463175109579/">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/piano_lancelot/">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="wrapperDev">
        © {new Date().getFullYear()}, Création
        {` `}
        <a href="https://www.mvdev.fr">MVDev</a>
      </div>
    </WrapperFooter>
  )
}

const WrapperFooter = styled.div`
  border-top: 1px solid #38b6ff;
  margin-top: -2.8rem;
  min-height: 250px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(56, 182, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  .wrapperFooterCli {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    ul {
      text-align: justify;
    }

    ul li a {
      color: #333;
    }

    ul li a:hover {
      color: #fff !important;
      transition: 0.5s ease-in-out;
    }

    h2 {
      margin: 2rem;
      font-family: 'Roboto Condensed', sans-serif;
    }

    h4 {
      font-family: 'Roboto Condensed', sans-serif;
    }

    .icon {
      display: flex;

      a {
        padding: 0 1rem;
        font-size: 2rem;
      }
    }
  }

  .wrapperDev {
    text-align: center;
    padding: 2rem 0 1rem 0;
    a {
      color: #38b6ff;
    }

    a:hover {
      color: #333 !important;
      transition: 0.5s ease-in-out;
    }
  }
`

export default Footer
